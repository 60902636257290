<template>
  <div class="w-full">
    <div v-if="isEditor">
      <slot />
    </div>
    <div v-else class="lg:w-1/4 m-auto mb-8">
      <div class="flex justify-center">
        <div class="bg-white w-[980px] mt-8 px-[25px] py-[35px]">
          <slot />
        </div>
      </div>
    </div>
    <footer class="text-center py-4">
      <p class="copy">
        ©︎Tech Forward - All Rights Reserved.
      </p>
    </footer>
  </div>
</template>
<script setup lang="ts">
const route = useRoute()
const isEditor = computed(() => {
  return route.path.includes('editor')
})
</script>
